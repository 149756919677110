<template>
  <div class="container">
    <h1>Boulageries de Bordeaux</h1>
    <div class="map">
      <l-map ref="map" v-model:zoom="zoom" :center="[44.833328, -0.56667]">
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          layer-type="base"
          name="OpenStreetMap"
        ></l-tile-layer>
        <l-marker v-for="marker in data" :lat-lng="marker.latlng" :key="marker.id">
          <l-tooltip>
            {{ marker.name }} <br/>
            {{ marker.note }} <br/>
            {{ marker.commentaire }}
          </l-tooltip>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import json from "./data.json"

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data() {
    return {
      zoom: 14,
      data: json,
    };
  },
};
</script>

<style scoped>
  .container {
    width: 100%;
    margin: auto;
  }

  .map {
    height: calc(90vh - 81px);
    width: 100%;
  }

  @media screen and (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  @media screen and (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  @media screen and (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  @media screen and (min-width: 992px) {
    .container {
      max-width: 1140px;
    }
  }
</style>