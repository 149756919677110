<template>
  <div id="layout">
    <hello-world></hello-world>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  components: {
    HelloWorld,
  },

  data: () => ({
  }),
};
</script>

<style></style>